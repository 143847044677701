import styled from 'styled-components';

export const LoadingImg = styled.img`
  min-width: 71px;
  width: 17%;
`;

export const LoadingBg1 = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const LoadingBg2 = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`;
